*{
    box-sizing: border-box
}

body{
    margin: 0;
    line-height: 1.4;
    /* color: rgb(190, 190, 243); */
}
.nav-link{
    color: black !important;
    text-transform: capitalize !important;
    font-size: 20px;
    font-weight: 700;
}
/* .head .active{
    color: #AA692D !important;
} */
.head .nav-link:hover{
    border-top: 2px solid teal !important
}
.head .nav-link button a:hover{
    border-top: none !important
}
.head .nav-link button{
    width: 107px
}
.slick-list{
    padding: 0 -20px !important
}
.slick-slide {
    padding: 0 20px !important
}

.signup-border{
    background-image: url('/images/banner-pattern.png');
    background-size: cover;
    background-position: bottom;
}
.signup-border input{
    background-color: floralwhite;
}
.inside{
    border-radius: 35px !important;
    box-shadow: 5px 10px rgb(91, 193, 227);
}
.plans .small{
    display: none !important;
}
@media (max-width: 768px) {
    .plans .big{
        display: none !important;
    }
    .plans .small{
        display: inline !important;
    }
    .btn-login{
        width: 100%;
    }
    #collapse-fontawesome{
        background-color: black !important;
    }
    #mynav .fa-bars{
        color: #475661
    }
    #responsive-navbar-nav{
        width: 100%;
        position: fixed;
        height: 550px;
        z-index: 1;
        padding: 15px;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 8);;
        border-radius: 0px 0px 15px 15px;
        background-image: url('/images/Group 1000001719.svg');
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 150px;
        overflow-y: hidden;
        transition: 0.5s;
    }
    .drop-logo{
        display: inline;
        margin-bottom: 60px;
    }
    .toggle{
        color: #475661 !important;
        font-size: 30px !important;
    }
    .nav-link{
        text-align: left !important;
        margin-bottom: 4px;
        padding-bottom: 5px;
        transition: 0.3s; 
      }
    .nav-link a:hover{
        color: #f1f1f1
    }
    .closebtn {
        position: absolute;
        display: inline !important;
        top: -12px;
        right: 35px;
        color: white;
        font-size: 60px;
    }
    .head {
        background-color: white !important;
    }
    .head .first{
        margin-top: 50px !important;
    }
    .header .container img{
        height: 80px !important;
        width: auto;
    }
    .head .nav-link{
        color: white !important;
        font-size: 24px;
        margin-left: 10px !important;
    }
    .head .nav-link button{
        width: 107px
    }
}